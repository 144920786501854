import { Flex, Heading, Link as ChakraLink, Button } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

export const VerifyEmailPage = () => {
    return (
        <Flex
            flexDirection="column"
            h="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Heading fontSize="4xl">Please verify your email!</Heading>
            <Button
                p={5}
                bgColor="getStartedYellow"
                size="3xl"
                mt="5vh"
                fontSize="3xl"
                fontFamily="fonts.heading"
                fontWeight={700}
                borderRadius="0.5rem"
                color="black"
                _hover={{ bg: "hoverYellow" }}
            >
                <ChakraLink
                    _hover={{ textDecoration: "none" }}
                    as={ReactRouterLink}
                    to="/login"
                >
                    Verified email
                </ChakraLink>
            </Button>
        </Flex>
    );
};

import { Flex, Box, useColorModeValue } from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import { colors } from "../theme/theme"
import { Toolbar } from "../components/Toolbar";
import { Document } from "../components/Document";
import { Controller } from "../editor/Controller";

export const EditorPage = () => {
    const controllerRef = useRef<Controller | null>(null);

    const toolbarBackground = useColorModeValue(colors.editor.light.toolbarBackground, colors.editor.darkPrimary.toolbarBackground);
    const documentPageColor = useColorModeValue(colors.editor.light.documentPageColor, colors.editor.darkPrimary.documentPageColor);
    const documentFontColor = useColorModeValue(colors.editor.light.documentFontColor, colors.editor.darkPrimary.documentFontColor);
    const documentBackgroundColor = useColorModeValue(colors.editor.light.documentBackgroundColor, colors.editor.darkPrimary.documentBackgroundColor);
    const codeBackgroundColor  = useColorModeValue(colors.editor.light.codeBackgroundColor, colors.editor.darkPrimary.codeBackgroundColor);
    const cursorColor = useColorModeValue("black", "white");

    useEffect(() => {
        document.documentElement.style.setProperty('--cursorColor', cursorColor);
        document.documentElement.style.setProperty('--codeBackgroundColor', codeBackgroundColor);
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--cursorColor', cursorColor);
        document.documentElement.style.setProperty('--codeBackgroundColor', codeBackgroundColor);
    }, [cursorColor, codeBackgroundColor])

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            if (controllerRef.current && !controllerRef.current.getSavedInCloud()) {
                const message = 'Are you sure you want to leave? You have unsaved changes.';
                event.returnValue = message; // For most browsers
                return message; // For some old browsers
            }
        };

        // Add the event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

    const onMouseDown = (e: any) => {
        e.preventDefault();
    }

    return (
            <Box bg={documentBackgroundColor} minHeight="100vh" tabIndex={-1} onMouseDown={onMouseDown}>
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Toolbar
                        ref={controllerRef}
                        position="sticky"
                        zIndex="10"
                        top="0"
                        bg={toolbarBackground}
                        w="100%"
                        h="10vh"
                        p="2rem"
                    />
                    <Document
                        ref={controllerRef}
                        overflow="hidden"
                        width="724px"
                        minHeight="1123px"
                        top="10vh"
                        marginTop="2rem"
                        backgroundColor={documentPageColor}
                        color={documentFontColor}
                        mb="2rem"
                        p="4rem"
                    />
                </Flex>
        </Box>
    );
};

import { Formik, Field, Form } from "formik";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack,
    FormHelperText,
    Heading,
    Flex,
    Spacer,
    InputGroup,
    InputRightElement,
    IconButton,
    useColorModeValue
} from "@chakra-ui/react";
import { LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { userPool } from "../App";

interface SignUpProps {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

export const SignUpPage = () => {
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            minHeight="100vh"
            h="auto"
            w="100%"
        >
            <Spacer />
            <SignUpForm />
            <Spacer />
            <Footer position="absolute" left="0" bottom="0" padding={3} />
        </Flex>
    );
};

const SignUpForm = () => {
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const borderColor = useColorModeValue("black", "white.400");

    const handleSubmit = (values: SignUpProps) => {
        const firstName = values.firstName.trim();
        const lastName = values.lastName.trim();
        const email = values.email.trim();
        const password = values.password;

        if (
            firstName == "" ||
            lastName == "" ||
            validateEmail(email) != "" ||
            validatePassword(password) != ""
        ) {
            return;
        }

        const attributeList = [
            new CognitoUserAttribute({
                Name: "email",
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: "given_name",
                Value: firstName,
            }),
            new CognitoUserAttribute({
                Name: "family_name",
                Value: lastName,
            }),
        ];

        userPool.signUp(email, password, attributeList, [], (err, result) => {
            if (err) {
                navigate("/error");
                return;
            }
            navigate("/verifyEmail");
        });
    };

    const validateEmail = (email: string) => {
        let error = "";
        if (!/^.*@.*\.edu$/.test(email)) {
            error = "A valid .edu email is required";
        }
        return error;
    };

    const validatePassword = (password: string) => {
        let error = "";
        const passwordRegex = /(?=.*[0-9])/;
        if (password.length < 8) {
            error = "Password must be 8 characters long.";
        } else if (!passwordRegex.test(password)) {
            error = "Invalid password. Must contain one number.";
        }
        setPassword(password);
        return error;
    };

    const validateConfirmPassword = (pass: string) => {
        let error = "Password not matched";
        if (pass && password && pass == password) {
            error = "";
        }
        return error;
    };
    return (
        <Box borderWidth={1} borderColor={borderColor} p="2rem" rounded="xl" w="40%">
            <Flex justifyContent="center" alignItems="center" pb="2rem">
                <Heading
                    fontFamily="fonts.heading"
                    fontWeight={800}
                    fontSize="3xl"
                >
                    Sign Up
                </Heading>
            </Flex>
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                }}
                onSubmit={(values, actions) => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <VStack spacing={4} align="flex-start">
                            <FormControl
                                isInvalid={
                                    errors.firstName != "" && touched.firstName
                                }
                                isRequired
                            >
                                <FormLabel htmlFor="firstName">
                                    First Name
                                </FormLabel>
                                <Field
                                    as={Input}
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    variant="ghost"
                                    validate={(value: string) =>
                                        value ? undefined : "Required"
                                    }
                                    placeholder="Arjun"
                                />
                                <FormErrorMessage>
                                    {errors.firstName}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={
                                    errors.lastName != "" && touched.lastName
                                }
                                isRequired
                            >
                                <FormLabel htmlFor="lastName">
                                    Last Name
                                </FormLabel>
                                <Field
                                    as={Input}
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    variant="ghost"
                                    validate={(value: string) =>
                                        value ? undefined : "Required"
                                    }
                                    placeholder="Guha"
                                />
                                <FormErrorMessage>
                                    {errors.lastName}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={errors.email != "" && touched.email}
                                isRequired
                            >
                                <FormLabel htmlFor="email">
                                    Email Address
                                </FormLabel>
                                <Field
                                    as={Input}
                                    id="email"
                                    name="email"
                                    type="email"
                                    variant="ghost"
                                    validate={validateEmail}
                                    placeholder="test@test.com"
                                />
                                {!errors.email && (
                                    <FormHelperText>
                                        We will never share your email.
                                    </FormHelperText>
                                )}
                                <FormErrorMessage>
                                    {errors.email}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={
                                    errors.password != "" && touched.password
                                }
                                isRequired
                            >
                                <FormLabel htmlFor="password">
                                    Password
                                </FormLabel>
                                <InputGroup>
                                    <Field
                                        as={Input}
                                        id="password"
                                        name="password"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        variant="ghost"
                                        validate={validatePassword}
                                        placeholder="**************"
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label="Show password"
                                            variant="ghost"
                                            icon={
                                                showPassword ? (
                                                    <UnlockIcon />
                                                ) : (
                                                    <LockIcon />
                                                )
                                            }
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors.password}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={
                                    errors.confirmPassword != "" &&
                                    touched.confirmPassword
                                }
                                isRequired
                            >
                                <FormLabel htmlFor="password">
                                    Confirm Password
                                </FormLabel>
                                <InputGroup>
                                    <Field
                                        as={Input}
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        variant="ghost"
                                        validate={validateConfirmPassword}
                                        placeholder="**************"
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label="Show password"
                                            variant="ghost"
                                            icon={
                                                showPassword ? (
                                                    <UnlockIcon />
                                                ) : (
                                                    <LockIcon />
                                                )
                                            }
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors.confirmPassword}
                                </FormErrorMessage>
                            </FormControl>

                            <Button
                                type="submit"
                                bg="primaryPurple"
                                _hover={{ bg: "hoverPurple" }}
                                width="full"
                                color="white"
                                rounded="xl"
                            >
                                Sign Up
                            </Button>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

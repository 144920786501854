import { Content } from "./Content";
import { Cursor } from "./Cursor";
import { BlockData } from "../Common";

export abstract class Block {
    protected text: string;

    constructor(text: string = "") {
        this.text = text;
    }

    // Overridden methods
    abstract styleEquals(other: Block): boolean;
    abstract render(content: Content, cursor: Cursor, pn: number, bn: number): HTMLElement;
    abstract serialize(): BlockData;
    abstract deSerialize(data: BlockData): void;

    public addText(textToAdd: string, startOffset: number, endOffset: number) {
        let before = this.text.substring(0, startOffset);
        let after = this.text.substring(endOffset);
        before += textToAdd;
        this.text = before + after;
    }

    public removeText(startOffset: number, endOffset: number, backwards: boolean = true) {
        let before = this.text.substring(0, startOffset);
        let after = this.text.substring(endOffset);

        if (startOffset == endOffset) {
            if (backwards) {
                before = before.substring(0, before.length - 1);
            } else {
                after = after.substring(1);
            }
        }
        this.text = before + after;
    }

    /* Utils */

    public charAt(offset: number): string {
        return this.text.charAt(offset);
    }

    public substring(startOffset: number, endOffset: number = this.length()): string {
        return this.text.substring(startOffset, endOffset);
    }

    public length(): number {
        return this.text.length;
    }

    /* Setters */

    public setText(text: string) {
        this.text = text;
    }

    /* Getters */

    public getText(): string {
        return this.text;
    }
}
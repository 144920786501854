import { Box } from "@chakra-ui/react";
import { forwardRef, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Controller } from "../editor/Controller";
import { AwsLibrary, ContentData } from "../Common";
import { Encrypter } from "../Encrypter";
import { Content } from "../editor/Content";
import "../editor/style.css";

export const Document = forwardRef((props: any, controllerRef: any) => {
    const navigate = useNavigate();
    let { encryptedDocumentName } = useParams();
    const editor = useRef<HTMLElement>(null);

    useEffect(() => {
        if (editor.current) {
            editor.current.focus({ preventScroll: true }); // Focus on the editor on page load
            if (encryptedDocumentName != undefined) {
                let [fullDocumentName, error] = Encrypter.decrypt(
                    encryptedDocumentName!
                );

                if (error != null) {
                    navigate("/error");
                } else {
                    fetchDocument(fullDocumentName);
                }
            } else {
                navigate("/error");
            }
        }
    }, []);

    const fetchDocument = async (documentKey: string) => {
        let [doc, error] = await AwsLibrary.getDocument(documentKey);
        if (error != null || doc == null) {
            navigate("/error");
        } else if (editor.current) {
            let contentData: ContentData = JSON.parse(doc.content!);
            let content = new Content();
            content.deSerialize(contentData);

            controllerRef.current = new Controller(editor.current, content);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (controllerRef.current) {
            controllerRef.current!.handleKeyDown(event);
        }
    };

    const handleMouseDown = (event: MouseEvent) => {
        if (controllerRef.current) {
            controllerRef.current.handleMouseDown(event);
        }
    };

    const handleCopy = (event: ClipboardEvent) => {
        if (controllerRef.current) {
            controllerRef.current.handleCopy(event);
        }
    };

    const handleCut = (event: ClipboardEvent) => {
        if (controllerRef.current) {
            controllerRef.current.handleCut(event);
        }
    };

    const handlePaste = (event: ClipboardEvent) => {
        if (controllerRef.current) {
            controllerRef.current.handlePaste(event);
        }
    };

    return (
        <Box
            className="editor"
            ref={editor}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            onCopy={handleCopy}
            onCut={handleCut}
            onPaste={handlePaste}
            tabIndex={0}
            {...props}
        />
    );
});

// 1. import `extendTheme` function
import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import '@fontsource/roboto'

// 2. Add your color mode config
const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

// Brand colors
export const colors = {
    primaryPurple: "#622CC2",
    hoverPurple: "#4D229A",
    getStartedYellow: "#FFE500",
    hoverYellow: "#EBC645",
    editor: {
        light: {
            toolbarBackground: "white",
            documentPageColor: "white",
            documentBackgroundColor: "#2B2F41",
            documentFontColor: "black",
            codeBackgroundColor: "#E8EAED",
        },
        darkPrimary: {
            toolbarBackground: "#080808",
            documentPageColor: "#1B202B",
            documentBackgroundColor: "#2B2F41",
            documentFontColor: "white",
            codeBackgroundColor: "#282A40",
        },
        darkSecondary: {
            toolbarBackground: "#080808",
            documentPageColor: "#1B191C",
            documentBackgroundColor: "black",
            documentFontColor: "white",
            codeBackgroundColor: "#37373C",
        },
    }
}

// Brand fonts
const fonts = {
    heading: 'Roboto',
}

// 3. extend the theme
const theme = extendTheme({ config }, { colors }, { fonts })

export default theme
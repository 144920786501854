import { Content } from "./Content"
import { Paragraph } from "./Paragraph"
import { Block } from "./Block";
import { TextBlock } from "./TextBlock"
import { LatexBlock } from "./LatexBlock";
import { Cursor } from "./Cursor"
import { ParagraphData, ParagraphDataType, BlockData, BlockDataType } from "../Common"

export class TextParagraph extends Paragraph {
    protected blocks: Block[];

    constructor(blocks = new Array<Block>()) {
        super();
        this.blocks = blocks;

        if (this.blocks.length == 0) {
            this.blocks.push(new TextBlock());
        }
    }

    public addBlock(index: number, block: Block) {
        this.blocks.splice(index, 0, block);
    }

    public render(content: Content, cursor: Cursor, paragraphNumber: number): HTMLElement {
        let div = document.createElement("div");
        div.classList.add("paragraph");
        div.classList.add("textparagraph")
        div.id = `${paragraphNumber}`;

        this.blocks.forEach((block: Block, index) => {
            div.appendChild(block.render(content, cursor, paragraphNumber, index));
        });
        return div;
    }

    public dynamicLatexMoveOut(cpBlockNumber: number, cpOffset: number, direction: number): [number, number] {
        return super.dynamicLatexMoveOutHelper(new TextBlock(), cpBlockNumber, cpOffset, direction);
    }

    /* Serialization methods for state and saving to/getting from cloud */

    public serialize(): ParagraphData {
        let serializedBlocks: BlockData[] = [];

        for (let block of this.blocks) {
            serializedBlocks.push(block.serialize());
        }

        let paragraphData: ParagraphData = {
            type: ParagraphDataType.TEXT,
            blocks: serializedBlocks,
        }

        return paragraphData;
    }

    public deSerialize(data: ParagraphData) {
        this.blocks = new Array<Block>();

        for (let blockData of data.blocks) {
            let block: Block = new TextBlock();

            if (blockData.type == BlockDataType.LATEX) {
                block = new LatexBlock();
            }
            block.deSerialize(blockData);
            this.blocks.push(block);
        }
    }
}
import { State } from "./State";
import { Content } from "./Content";
import { Paragraph } from "./Paragraph";
import { LatexParagraph } from "./LatexParagraph";
import { TextStyle } from "./TextBlock";
import { LatexStyle } from "./LatexBlock";
import { Cursor } from "./Cursor";
import { CursorPosition } from "./CursorPosition";
import "katex/dist/katex.min.css";
import { MathQuillLoader } from "mathquill-typescript"
import { animateScroll } from 'react-scroll';

export class Controller {
    public static CLICK_DELAY: number = 500;
    public static SCROLL_DURATION: number = 100;
    public static SAVE_DURATION: number = 500;
    public static CHARACTERS = new Set(["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","[","]","\\",";","'",",",".","/","{","}","|",":","\"","<",">","?","1","2","3","4","5","6","7","8","9","0","!","@","#","$","%","^","&","*","(",")","~","`","-","_","+","="]);
    public static ADDITIONAL_EDIT_KEYS = new Set(["Backspace", "Delete", "Enter", "Tab", " "]);
    public static NAVIGATION_KEYS = new Set(["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"]);
    public static ALL_KEYS = new Set([...this.CHARACTERS, ...this.ADDITIONAL_EDIT_KEYS, ...this.NAVIGATION_KEYS]);

    private MQ: any;
    private editor: HTMLElement;
    private state: State;
    private previousClickPosition: CursorPosition;
    private clicks: number;
    private scrolling: boolean;
    private saveTimerActive: boolean;
    private savedInCloud: boolean;
    private wasInLatex: [number, number];

    constructor(editor: HTMLElement, content = new Content()) {
        MathQuillLoader.loadMathQuill({'mode': 'prod'}, (mathquill: any) => {
            this.MQ = mathquill.getInterface(2);
        })
        this.editor = editor;
        this.state = new State(content, new Cursor(content));
        this.state.save();
        this.previousClickPosition = new CursorPosition(0,0,0);
        this.clicks = 0;
        this.scrolling = false;
        this.saveTimerActive = false;
        this.savedInCloud = true;
        this.wasInLatex = this.state.cursor().activeLatexBlockPosition();

        this.renderEverything();
    }

    public handleBold(e: any) {
        this.textStyleHandler(TextStyle.BOLD);
        e.target.blur();
    }

    public handleItalic(e: any) {
        this.textStyleHandler(TextStyle.ITALIC);
        e.target.blur();
    }

    public handleUnderline(e: any) {
        this.textStyleHandler(TextStyle.UNDERLINE);
        e.target.blur();
    }

    public handleStrikethrough(e: any) {
        this.textStyleHandler(TextStyle.STRIKETHROUGH);
        e.target.blur();
    };

    public handleClearFormatting(e: any) {
        this.textStyleHandler(null);
        e.target.blur();
    };

    public handleDynamicLatex(e: any) {
        this.latexStyleHandler(LatexStyle.DYNAMIC);
        e.target.blur();
    };

    public handleVanillaLatex(e: any) {
        this.latexStyleHandler(LatexStyle.VANILLA);
        e.target.blur();
    };

    public handleLatexParagraph(e: any) {
        this.insertParagraphHandler(new LatexParagraph());
        e.target.blur();
    };

    public async handleUndo() {
        this.state.undo();
        this.savedInCloud = false;

        this.removeCursor(); // renders just the content
        await this.scrollIfNeeded();
        this.renderEverything();
    }

    public async handleRedo() {
        this.state.redo();
        this.savedInCloud = false;

        this.removeCursor(); // renders just the content
        await this.scrollIfNeeded();
        this.renderEverything();
    }

    public handleSaveInCloud() {
        this.savedInCloud = true;
    }

    public handleCopy(e: ClipboardEvent) {
        e.preventDefault();
        e.clipboardData!.setData("text/plain", this.state.cursor().getSelectionText());
    }

    public async handleCut(e: ClipboardEvent) {
        e.preventDefault();
        this.state.save();

        e.clipboardData!.setData("text/plain", this.state.cursor().getSelectionText());
        this.state.content().cut(this.state.cursor());
        this.state.cursor().cut();
        this.savedInCloud = false;

        this.removeCursor(); // renders just the content
        await this.scrollIfNeeded();
        this.renderEverything();
        this.state.save();
    }

   public async handlePaste(e: ClipboardEvent) {
        e.preventDefault();
        this.state.save();

        let paste = (e.clipboardData || (window as any).clipboardData).getData("text");

        this.state.content().paste(paste, this.state.cursor());
        this.state.cursor().paste(paste);
        this.savedInCloud = false;

        this.removeCursor(); // renders just the content
        await this.scrollIfNeeded();
        this.renderEverything();
        this.state.save();
    }

    public async handleKeyDown(e: KeyboardEvent) {
        if (!this.tupleEquals(this.wasInLatex, [-1, -1])) {
            this.wasInLatex = this.state.cursor().activeLatexBlockPosition();
            return;
        }

        this.wasInLatex = this.state.cursor().activeLatexBlockPosition();

        if (e.metaKey) {
            if (e.key == 'a') {
                e.preventDefault();
                this.removeCursor();
                this.state.cursor().selectAll();
                this.renderEverything();
            } else if (e.key == 'b') {
                this.textStyleHandler(TextStyle.BOLD);
            } else if (e.key == 'i') {
                e.preventDefault();
                this.textStyleHandler(TextStyle.ITALIC);
            } else if (e.key == 'u') {
                this.textStyleHandler(TextStyle.UNDERLINE);
            } else if (e.key == 'x' && e.shiftKey) {
                this.textStyleHandler(TextStyle.STRIKETHROUGH);
            } else if (e.key == 'z' && e.shiftKey) {
                this.handleRedo();
            } else if (e.key == 'z') {
                this.handleUndo();
            } else if (e.key == '\\') {
                e.preventDefault();
                this.textStyleHandler(null);
            }
        }

        if (!e.metaKey && (Controller.CHARACTERS.has(e.key) || Controller.ADDITIONAL_EDIT_KEYS.has(e.key))) {
            this.savedInCloud = false;
            let inSelection = this.state.cursor().inASelection();
            if (inSelection) {
                this.state.save();
            } else if (!this.saveTimerActive) {
                this.saveTimerActive = true;
                setTimeout(() => {
                    this.state.save();
                    this.saveTimerActive = false;
                }, Controller.SAVE_DURATION);
            }

            if (e.key == "Backspace") {
                this.state.content().backspace(this.state.cursor());
                this.state.cursor().backspace();
            } else if (e.key == "Delete") {
                this.state.content().delete(this.state.cursor());
                this.state.cursor().delete();
            } else if (e.key == "Enter") {
                this.state.content().addText('\n', this.state.cursor());
                this.state.cursor().insertCharacter();
            } else if (e.key == "Tab") {
                e.preventDefault();
                this.state.content().addText('\t', this.state.cursor());
                this.state.cursor().insertCharacter();
            } else if (e.key == " ") {
                e.preventDefault();
                this.state.content().addText(' ', this.state.cursor());
                this.state.cursor().insertCharacter();
            } else if (Controller.CHARACTERS.has(e.key)) {
                this.state.content().addText(e.key, this.state.cursor());
                this.state.cursor().insertCharacter();
            }
            this.removeCursor();
            await this.scrollIfNeeded();
            this.renderEverything();

            if (inSelection) {
                this.state.save();
            }
        } else if (Controller.NAVIGATION_KEYS.has(e.key)) {
            this.removeCursor();

            if (e.key == 'ArrowLeft') {
                this.state.cursor().arrowLeft(e.shiftKey);
                await this.scrollIfNeeded();
            } else if (e.key == 'ArrowRight') {
                this.state.cursor().arrowRight(e.shiftKey);
                await this.scrollIfNeeded();
            } else if (e.key == 'ArrowUp') {
                e.preventDefault();
                let scrollFirst = (!this.state.cursor().inASelection() || e.shiftKey)

                if (scrollFirst) {
                    if (this.scrolling) {
                        return;
                    }
                    await this.scrollIfNeeded();
                }

                // Calculate one up shift of selectionEnd
                let newSelectionRect = this.getBoundingRectFromOffset(this.state.cursor().getSelectionEnd());
                let newSelectionEndRange = this.getXYCaretRange(newSelectionRect.left, newSelectionRect.bottom - (newSelectionRect.bottom - newSelectionRect.top + 3));
                let newSelectionEndPosition = this.cursorPositionFromRange(newSelectionEndRange);
                this.state.cursor().arrowUp(e.shiftKey, newSelectionEndPosition);
                await this.scrollIfNeeded();
            } else if (e.key == 'ArrowDown') {
                e.preventDefault();
                let scrollFirst = (!this.state.cursor().inASelection() || e.shiftKey)

                if (scrollFirst) {
                    if (this.scrolling) {
                        return;
                    }
                    await this.scrollIfNeeded();
                }
                // Calculate one down shift of selectionEnd
                let newSelectionRect = this.getBoundingRectFromOffset(this.state.cursor().getSelectionEnd());
                let newSelectionEndRange = this.getXYCaretRange(newSelectionRect.left, newSelectionRect.bottom + (newSelectionRect.bottom - newSelectionRect.top + 1));
                let newSelectionEndPosition = this.cursorPositionFromRange(newSelectionEndRange);
                this.state.cursor().arrowDown(e.shiftKey, newSelectionEndPosition);
                await this.scrollIfNeeded();
            }
            this.renderEverything();
        }
    }

    public handleMouseDown(evt: MouseEvent) {
        evt.preventDefault();
        this.wasInLatex = this.state.cursor().activeLatexBlockPosition();

        if (evt.button == 0) {
            this.removeCursor();
            this.clicks++;

            setTimeout(() => {
                this.clicks = 0;
            }, Controller.CLICK_DELAY);

            let clickRange = this.getXYCaretRange(evt.clientX, evt.clientY);
            let newClickPosition = this.cursorPositionFromRange(clickRange);

            if (this.clicks == 3 && this.previousClickPosition.equals(newClickPosition)) {
                this.tripleClickAction();
                this.clicks = 0;
            } else if (this.clicks == 2 && this.previousClickPosition.equals(newClickPosition)) {
                this.doubleClickAction();
            } else {
                this.singleClickAction(evt);
            }

            if (newClickPosition != null) {
                this.previousClickPosition = newClickPosition;
            }
        }
    }

    public singleClickAction(evt: MouseEvent) {
        let clickRange = this.getXYCaretRange(evt.clientX, evt.clientY);
        let newCursorPosition = this.cursorPositionFromRange(clickRange);

        if (newCursorPosition != null) {
            this.state.cursor().mouseDown(newCursorPosition);
        } else if (this.tupleEquals(this.state.cursor().activeLatexBlockPosition(), [-1, -1])) {
            let finalParagraphNumber = this.state.content().length() - 1;
            let finalBlockNumber = this.state.content().at(finalParagraphNumber).length() - 1;
            let finalBlockLength = this.state.content().at(finalParagraphNumber).at(finalBlockNumber).length();
            newCursorPosition = new CursorPosition(finalParagraphNumber, finalBlockNumber, finalBlockLength);
            this.state.cursor().mouseDown(newCursorPosition);
        }

        this.renderEverything();

        this.editor.onmousemove = (evt: MouseEvent) => {
            evt.preventDefault();
            this.removeCursor();

            let moveRange = this.getXYCaretRange(evt.clientX, evt.clientY);
            let newSelectionEnd = this.cursorPositionFromRange(moveRange);

            if (newSelectionEnd != null) {
                this.state.cursor().mouseMove(newSelectionEnd);
            }
            this.renderEverything();
        }
    
        this.editor.onmouseup = (evt: MouseEvent) => {
            evt.preventDefault();
            this.editor.onmousemove = null;
            this.editor.onmouseup = null;
        }
    }

    public doubleClickAction() {
        this.state.cursor().doubleClick();

        this.renderEverything();
    };

    public tripleClickAction() {
        this.state.cursor().tripleClick();

        this.renderEverything();
    };


    public renderEverything() {
        if (
            this.tupleEquals(this.wasInLatex, this.state.cursor().activeLatexBlockPosition()) && 
            !this.tupleEquals(this.state.cursor().activeLatexBlockPosition(), [-1, -1])
        ) {
            return;
        }

        this.state.content().purify(this.state.cursor());
        this.state.content().coalesce(this.state.cursor());
        this.state.content().render(this.editor, this.state.cursor());
        this.state.cursor().render(this.editor);
        this.editor.focus({ preventScroll: true });

        if (!this.state.cursor().inASelection() && (!(this.tupleEquals(this.wasInLatex, this.state.cursor().activeLatexBlockPosition()))
            && (!this.tupleEquals(this.state.cursor().activeLatexBlockPosition(), [-1, -1])))) {
            this.wasInLatex = this.state.cursor().activeLatexBlockPosition();
            let activeParagraphNumber = this.state.cursor().getCursorPosition().getParagraphNumber();
            let activeBlockNumber = this.state.cursor().getCursorPosition().getBlockNumber();
            let activeLatexBlockElement = document.getElementById(`${activeParagraphNumber}-${activeBlockNumber}`);

            activeLatexBlockElement?.addEventListener("blur", function(this: Controller) {
                this.state.save();

                // Because mathquill is weird, the "keydown" event is called before this mathquill handler
                // and so an explicit statment setting wasInLatex to [-1, -1] is required.
                // Note: in HTML, only the "enter" mathquill handler behaves this way. But, in react, all the mathquill
                // handlers behave this way.
                this.wasInLatex = [-1, -1];
            }.bind(this), true);

            let mathField = this.MQ.MathField(activeLatexBlockElement!, {
                spaceBehavesLikeTab: false,
                handlers: {
                    edit: async () => {
                        // Note: this handler is also called after deleteOutOf for some weird reason :(
                        this.state.content().dynamicLatexEdit(this.state.cursor(), mathField.latex());
                        this.savedInCloud = false;
                        await this.scrollIfNeeded();
                    },
                    moveOutOf: async (direction: number, _mathField: any) => {
                        let [newCursorPosition, newSelectionEnd] = this.state.content().dynamicLatexMoveOut(this.state.cursor(), direction);

                        this.state.cursor().dynamicLatexMoveOut(newCursorPosition, newSelectionEnd, direction);
                        this.removeCursor(); // renders just the content
                        await this.scrollIfNeeded();
                        this.renderEverything();
                        this.state.save();

                        // Because mathquill is weird, the "keydown" event is called before this mathquill handler
                        // and so an explicit statment setting wasInLatex to [-1, -1] is required.
                        // Note: in HTML, only the "enter" mathquill handler behaves this way. But, in react, all the mathquill
                        // handlers behave this way.
                        this.wasInLatex = [-1, -1];

                        // Focus back on the editor to allow users to type again
                        this.editor.focus({ preventScroll: true});
                    },
                    enter: async (_mathField: any) => {
                        let [newCursorPosition, newSelectionEnd] = this.state.content().dynamicLatexMoveOut(this.state.cursor(), this.MQ.R);
                        this.state.cursor().dynamicLatexMoveOut(newCursorPosition, newSelectionEnd, this.MQ.R);
                        this.removeCursor(); // renders just the content
                        await this.scrollIfNeeded();
                        this.renderEverything();
                        this.state.save();

                        // Because mathquill is weird, the "keydown" event is called before this mathquill handler
                        // and so an explicit statment setting wasInLatex to [-1, -1] is required.
                        // Note: in HTML, only the "enter" mathquill handler behaves this way. But, in react, all the mathquill
                        // handlers behave this way.
                        this.wasInLatex = [-1, -1];

                        // Focus back on the editor to allow users to type again
                        this.editor.focus({ preventScroll: true});
                    },
                    deleteOutOf: async (_direction: number, _mathField: any) => {
                        let [newCursorPosition, newSelectionEnd] = this.state.content().dynamicLatexMoveOut(this.state.cursor(), this.MQ.L);
                        this.state.cursor().dynamicLatexMoveOut(newCursorPosition, newSelectionEnd, this.MQ.L);
                        this.removeCursor(); // renders just the content
                        await this.scrollIfNeeded();
                        this.renderEverything();
                        this.state.save();

                        // Because mathquill is weird, the "keydown" event is called before this mathquill handler
                        // and so an explicit statment setting wasInLatex to [-1, -1] is required.
                        // Note: in HTML, only the "enter" mathquill handler behaves this way. But, in react, all the mathquill
                        // handlers behave this way.
                        this.wasInLatex = [-1, -1];

                        // Focus back on the editor to allow users to type again
                        this.editor.focus({ preventScroll: true});
                    }
                }
            });
            let initialLatex = this.state.content().at(activeParagraphNumber).at(activeBlockNumber).getText();
            mathField.focus();

            // Clear the current mathfield from latex, and rewrite the content to it
            mathField.latex("");
            mathField.write(initialLatex);
        }
    }

    public removeCursor() {
        if (!this.state.cursor().inDynamicLatex()) {
            this.state.content().render(this.editor, this.state.cursor());
        }
    }

    public async textStyleHandler(style: TextStyle | null) {
        let inSelection = this.state.cursor().inASelection();
        if (inSelection) {
            this.state.save();
        }
        let [newCursorPosition, newSelectionEnd] = this.state.content().textStyle(this.state.cursor(), style);
        this.state.cursor().style(newCursorPosition, newSelectionEnd);
        this.savedInCloud = false;

        if (inSelection) { // We only want to scroll when a chunk of text changes styles.
            this.removeCursor(); // renders just the content
            await this.scrollIfNeeded();
        }
        this.renderEverything();
        if (inSelection) {
            this.state.save();
        }
    }

    public async latexStyleHandler(style: LatexStyle) {
        if (this.state.cursor().inASelection()) {
            return;
        }

        if (style == LatexStyle.DYNAMIC) {
            this.state.save();
        }
        let [newCursorPosition, newSelectionEnd] = this.state.content().latexStyle(this.state.cursor(), style);
        this.state.cursor().style(newCursorPosition, newSelectionEnd);
        this.savedInCloud = false;

        // we do not want to scroll to newly created latex blocks (we get bounding rect errors)

        this.renderEverything();
    }

    public async insertParagraphHandler(paragraph: Paragraph) {
        let [newCursorPosition, newSelectionEnd] = this.state.content().insertParagraph(this.state.cursor(), paragraph);
        this.state.cursor().insertParagraph(newCursorPosition, newSelectionEnd);
        this.savedInCloud = false;

        this.removeCursor();
        await this.scrollIfNeeded(); // renders just the content
        this.renderEverything();
    }

    public cursorPositionFromRange(clickRange: Range | null): CursorPosition {
        if (clickRange == null || clickRange.startContainer == null) {
            return new CursorPosition(0, 0, 0);
        }

        let elementNode: any = clickRange.startContainer;
        if (elementNode.nodeType != Node.ELEMENT_NODE) {
            elementNode = elementNode.parentElement;
        }

        if (elementNode == null) {
            return new CursorPosition(0, 0, 0);
        }

        let paragraphElement = elementNode.closest(".paragraph");
        let blockElement = elementNode.closest(".block");

        if (paragraphElement == null) {
            return new CursorPosition(0, 0, 0);
        }

        let paragraphNum = parseInt(paragraphElement.id);
        if (isNaN(paragraphNum)) {
            return new CursorPosition(0, 0, 0);
        }

        if (blockElement == null) {
            return new CursorPosition(paragraphNum, 0, 0); // we are in a paragraph with only a single empty block
        }

        let blockNum = parseInt(blockElement.id.split("-")[1])
        if (isNaN(blockNum)) {
            return new CursorPosition(0, 0, 0);
        }

        return new CursorPosition(paragraphNum, blockNum, clickRange.startOffset);
    }

    /* Scrolling Functions */

    public async scrollIfNeeded() {
        // A note: this method can only be called when everything is rendered except the cursor.
        // So, the general flow is: 1) Compute, 2) Render content, 3) Scroll, 4) Render Everything, 5) Save.
        if (this.cursorNotVisibile()) {
            this.scrolling = true;
            let sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
            animateScroll.scrollTo(this.toScrollPosition(), {duration: Controller.SCROLL_DURATION, smooth: 'easeInOutQuad'});
            await sleep(Controller.SCROLL_DURATION); // Sleep to allow scrolling to complete
            this.scrolling = false;
        }
    }

    // returns the position we should scroll to
    public toScrollPosition(): number {
        let cursorRect = this.getBoundingRectFromOffset(this.state.cursor().getSelectionEnd());
        let cursorTop = cursorRect.top + window.scrollY;
        let cursorHeight = cursorRect.height;
        let viewPortHeight = window.innerHeight;
        let toolbarPixelsHeight = (viewPortHeight * 10) / 100;
        let positionToScrollTo = cursorTop - toolbarPixelsHeight - cursorHeight;
        return positionToScrollTo;
    }

    public cursorNotVisibile(): boolean {
        let cursorRect = this.getBoundingRectFromOffset(this.state.cursor().getSelectionEnd());
        let cursorTop = cursorRect.top + window.scrollY;
        let cursorHeight = cursorRect.height;
        let viewPortHeight = window.innerHeight;
        let toolbarPixelsHeight = (viewPortHeight * 10) / 100;

        // cursorTooHigh is true if the cursor is not visibile because it is to high for us to see.
        let cursorTooHigh = window.scrollY + toolbarPixelsHeight > cursorTop - cursorHeight;

        // cursorTooLow is true if the cursor is not visibile because it is too low for us to see.
        let cursorTooLow = viewPortHeight + window.scrollY < cursorTop + 2 * cursorHeight;

        return cursorTooHigh || cursorTooLow;
    }

    public getBoundingRectFromOffset(cursorPosition: CursorPosition): DOMRect {
        let paragraphElement = this.editor.children[cursorPosition.getParagraphNumber()];
        let blockElement = paragraphElement.children[cursorPosition.getBlockNumber()];

        // Did this so that a cursorPosition in dynamic latex does not just have a (0,0,0,0) bounding
        // rect for scrolling
        if (cursorPosition.inDynamicLatex(this.state.content())) {
            return blockElement.getBoundingClientRect();
        }

        if (blockElement.firstChild == null) {
            return paragraphElement.getBoundingClientRect();
        }
        let range = document.createRange()
        range.setStart(blockElement.firstChild, cursorPosition.getOffset());
        range.setEnd(blockElement.firstChild, cursorPosition.getOffset());
        return range.getBoundingClientRect();
    }

    // an amazing function that gets a range object containing
    // accurate offset information based on the cursor position
    public getXYCaretRange(x: number, y: number): (Range | null) {
        let range = null;

        if ((document as any).caretPositionFromPoint) { // standards-based way (supported by firefox)
            let pos = (document as any).caretPositionFromPoint(x, y);
            range = document.createRange();
            range.setStart(pos.offsetNode, pos.offset);
            range.collapse(true);
        } else if (document.caretRangeFromPoint) { // webkit way (supported by all other browsers)
            range = document.caretRangeFromPoint(x, y);
        }
        return range;
    }

    public tupleEquals([a, b]: [number, number], [c, d]: [number, number]): boolean {
        return a == c && b == d;
    }

    /* Getters */

    public getEditorHTMLElement(): HTMLElement {
        return this.editor;
    }

    public getState(): State {
        return this.state;
    }

    public getSavedInCloud(): boolean {
        return this.savedInCloud;
    }
}
import {
    Flex,
    Spacer,
    Text,
    Center,
    Button,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { ColorModeSwitcherLocation, UserData } from "../Common";
import { useAuth } from "../auth/Authentication";

export const Navbar = () => {
    const { useIsLoggedIn } = useAuth();

    return (
        <Flex bg="primaryPurple" w="100%" padding={4} height="8vh">
            <Center>
                <Text
                    fontSize="4xl"
                    fontFamily="fonts.heading"
                    fontWeight={600}
                    padding={10}
                    color="white"
                >
                    <ChakraLink
                        _hover={{ textDecoration: "none" }}
                        as={ReactRouterLink}
                        to="/"
                    >
                        Parchmynt
                    </ChakraLink>
                </Text>
            </Center>
            <Spacer />
            <Center>
                <Button
                    size="lg"
                    padding={5}
                    fontSize={"24"}
                    fontFamily="fonts.heading"
                    fontWeight={400}
                    color="white"
                    variant="ghost"
                    _hover={{ bg: "hoverPurple" }}
                >
                    <ChakraLink
                        _hover={{ textDecoration: "none" }}
                        as={ReactRouterLink}
                        to="/about"
                    >
                        About
                    </ChakraLink>
                </Button>
                {useIsLoggedIn() ? (
                    <AuthNavComponent />
                ) : (
                    <NonAuthNavComponent />
                )}
                <ColorModeSwitcher
                    enumValue={ColorModeSwitcherLocation.NAVBAR}
                />
            </Center>
        </Flex>
    );
};

const AuthNavComponent = () => {
    const { logout, useGetUserData } = useAuth();
    const navigate = useNavigate();
    const userData = useGetUserData();

    const handleSignOut = () => {
        const route = logout();
        navigate(route);
    };

    return (
        <Flex>
            <Menu>
                <MenuButton
                    size="lg"
                    padding={5}
                    fontSize={"24"}
                    fontFamily="fonts.heading"
                    fontWeight={400}
                    color="white"
                    variant="ghost"
                    _hover={{ bg: "hoverPurple" }}
                    _active={{ bg: "hoverPurple" }}
                    as={Button} rightIcon={<ChevronDownIcon />}
                >
                    {userData.firstName}
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    );
};

const NonAuthNavComponent = () => {
    return (
        <Flex>
            <Button
                size="lg"
                padding={5}
                fontSize={"24"}
                fontFamily="fonts.heading"
                fontWeight={400}
                color="white"
                variant="ghost"
                _hover={{ bg: "hoverPurple" }}
            >
                <ChakraLink
                    _hover={{ textDecoration: "none" }}
                    as={ReactRouterLink}
                    to="/login"
                >
                    Login
                </ChakraLink>
            </Button>
            <Button
                size="lg"
                padding={5}
                fontSize={"24"}
                fontFamily="fonts.heading"
                fontWeight={400}
                color="white"
                variant="ghost"
                _hover={{ bg: "hoverPurple" }}
            >
                <ChakraLink
                    _hover={{ textDecoration: "none" }}
                    as={ReactRouterLink}
                    to="/signup"
                >
                    Sign Up
                </ChakraLink>
            </Button>
        </Flex>
    );
};

import { Content } from "./Content";
import { Block } from "./Block"
import { Cursor } from "./Cursor";
import { BlockDataType, TextBlockData } from "../Common"

export enum TextStyle {
    BOLD = "bold",
    ITALIC = "italic",
    UNDERLINE = "underline",
    STRIKETHROUGH = "strikethrough",
}

export class TextBlock extends Block {
    private styles: Map<TextStyle, boolean>;

    constructor(text: string = "", appliedStyles: Set<TextStyle> = new Set<TextStyle>()) {
        super(text);
        this.styles = new Map<TextStyle, boolean>([
            [TextStyle.BOLD, false],
            [TextStyle.ITALIC, false],
            [TextStyle.UNDERLINE, false],
            [TextStyle.STRIKETHROUGH, false],
        ]);

        for (let appliedStyle of appliedStyles) {
            this.styles.set(appliedStyle, true)
        }
    }

    public styleEquals(other: Block): boolean {
        if (!(other instanceof TextBlock)) {
            return false;
        }

        if (this.styles.size != other.styles.size) {
            return false;
        }

        for (let [style, value] of this.styles) {
            if (!other.styles.has(style) || value != other.styles.get(style)) {
                return false;
            }
        }

        return true;
    }

    public render(_content: Content, _cursor: Cursor, pn: number, bn: number): HTMLElement {
        let div = document.createElement("div");
        div.classList.add("block");
        div.classList.add("textblock")

        for (let className of this.classList()) {
            div.classList.add(className);
        }
        div.id = `${pn}-${bn}`;
        div.innerHTML = this.text;
        return div;
    }

    public classList(): string[] {
        let classList = [];
        let appliedStyles = this.getAppliedStyles();

        if (appliedStyles.has(TextStyle.STRIKETHROUGH) && appliedStyles.has(TextStyle.UNDERLINE)) {
            classList.push("underline-strikethrough");
            appliedStyles.delete(TextStyle.STRIKETHROUGH);
            appliedStyles.delete(TextStyle.UNDERLINE)
        }

        for (let style of appliedStyles) {
            classList.push(style);
        }

        return classList;
    }

    /* Setters */

    public setAppliedStyles(appliedStyles: Set<TextStyle>) {
        for (let style of this.styles.keys()) {
            this.styles.set(style, appliedStyles.has(style));
        }
    }

    public setStyle(style: TextStyle | null, value: boolean) {
        if (style == null) {
            for (let s of this.styles.keys()) {
                this.styles.set(s, false);
            }
        } else {
            this.styles.set(style, value);
        }
    }

    /* Getters */

    public getStyle(style: TextStyle | null): boolean {
        if (style == null) {
            return false;
        }
        return this.styles.get(style)!;
    }

    public getAppliedStyles(): Set<TextStyle> {
        let appliedStyles =  new Set<TextStyle>();

        for (let [style, value] of this.styles) {
            if (value) {
                appliedStyles.add(style);
            }
        }
        return appliedStyles;
    }

    /* Serialization methods for state and saving to/getting from cloud */

    public serialize(): TextBlockData {
        let serializedStyles: { [key: string]: boolean } = {};
        for (let [style, value] of this.styles) {
            serializedStyles[style] = value;
        }
        let textBlockData: TextBlockData = {
            type: BlockDataType.TEXT,
            text: this.text,
            styles: serializedStyles
        }

        return textBlockData;
    }

    public deSerialize(data: TextBlockData) {
        this.styles = new Map<TextStyle, boolean>();

        for (let [style, value] of Object.entries(data.styles)) {
            this.styles.set(style as unknown as TextStyle, value);
        }
        this.text = data.text;
    }
}

import { Content } from "./Content"
import { Paragraph } from "./Paragraph"
import { LatexBlock } from "./LatexBlock"
import { Cursor } from "./Cursor"
import { ParagraphData, ParagraphDataType, LatexBlockData } from "../Common"
import katex from "katex";

export class LatexParagraph extends Paragraph {
    protected blocks: LatexBlock[];

    constructor(latexBlocks = new Array<LatexBlock>()) {
        super();
        this.blocks = latexBlocks;

        if (this.blocks.length == 0) {
            this.blocks.push(new LatexBlock());
        }
    }

    public addBlock(index: number, latexBlock: LatexBlock) {
        this.blocks.splice(index, 0, latexBlock);
    }

    public render(content: Content, cursor: Cursor, paragraphNumber: number): HTMLElement {
        let div = document.createElement("div");
        div.classList.add("paragraph");
        div.classList.add("latexparagraph")
        div.id = `${paragraphNumber}`;

        if (!cursor.insideParagraph(paragraphNumber)) {
            div.innerHTML = katex.renderToString(this.substring(0, 0), {throwOnError: false});
        } else {
            this.blocks.forEach((latexBlock: LatexBlock, index) => {
                div.appendChild(latexBlock.render(content, cursor, paragraphNumber, index));
            });
            div.classList.add("code");
        }
        return div;
    }

    public dynamicLatexMoveOut(cpBlockNumber: number, cpOffset: number, direction: number): [number, number] {
        return super.dynamicLatexMoveOutHelper(new LatexBlock(), cpBlockNumber, cpOffset, direction);
    }

    /* Serialization methods for state and saving to/getting from cloud */

    public serialize(): ParagraphData {
        let serializedBlocks: LatexBlockData[] = [];

        for (let block of this.blocks) {
            serializedBlocks.push(block.serialize());
        }

        let paragraphData: ParagraphData = {
            type: ParagraphDataType.LATEX,
            blocks: serializedBlocks,
        }

        return paragraphData;
    }

    public deSerialize(data: ParagraphData) {
        this.blocks = new Array<LatexBlock>()

        for (let blockData of data.blocks) {
            let block = new LatexBlock();
            block.deSerialize((blockData as LatexBlockData));
            this.blocks.push(block);
        }
    }
}
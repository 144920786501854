import { Flex, Heading } from "@chakra-ui/react";

export const ErrorPage = () => {
    return (
        <Flex h="100vh" justifyContent="center" alignItems="center">
            <Heading fontSize="4xl">
                You've experienced an error, please try again later.
            </Heading>
        </Flex>
    );
};

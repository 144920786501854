import { Box, Spacer } from "@chakra-ui/react";
import { Footer } from "../components/Footer";

export const AboutPage = () => {
    return (
        <Box minHeight="100vh" h="auto" w="100%">
            <Box>About section</Box>
            <Footer position="absolute" left="0" bottom="0" padding={3} />
        </Box>
    );
};

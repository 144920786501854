import * as React from "react";
import {
    useColorMode,
    useColorModeValue,
    IconButton,
    IconButtonProps,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import { ColorModeSwitcherLocation } from "../Common";

interface ColorModeSwitcherProps {
    enumValue: ColorModeSwitcherLocation;
}

export const ColorModeSwitcher = ({ enumValue }: ColorModeSwitcherProps) => {
    const { toggleColorMode } = useColorMode();
    const text = useColorModeValue("dark", "light");
    const SwitchIcon = useColorModeValue(FaMoon, FaSun);

    return (
        <IconButton
            fontSize="2xl"
            variant="ghost"
            color={
                enumValue == ColorModeSwitcherLocation.EDITOR
                    ? text
                    : "white"
            }
            padding={2}
            onClick={toggleColorMode}
            icon={<SwitchIcon />}
            aria-label={`Switch to ${text} mode`}
            _hover={{ bg: "hoverPurple", color: "white" }}
        />
    );
};

import { Flex, Box, Heading, Button } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";

export const IntroSection = () => {
    return (
        <Flex
            flexDirection="column"
            justifyContent="center"
            marginLeft="15%"
            w="100%"
            h="87vh"
        >
            <Flex flexDirection="column" justifyContent="center">
                <Box mb={4}>
                    <Heading fontSize={["4xl", "6xl", "8xl"]}>
                        Write Beautiful LaTeX
                    </Heading>
                    <Heading fontSize={["4xl", "6xl", "8xl"]}>Fast.</Heading>
                    <Button
                        p={5}
                        bgColor="getStartedYellow"
                        size="3xl"
                        mt="5vh"
                        fontSize="3xl"
                        fontFamily="fonts.heading"
                        fontWeight={700}
                        borderRadius="0.5rem"
                        color="black"
                        _hover={{ bg: "hoverYellow" }}
                    >
                        <ChakraLink
                            _hover={{ textDecoration: "none" }}
                            as={ReactRouterLink}
                            to="/login"
                        >
                            Get Started
                        </ChakraLink>
                    </Button>
                </Box>
            </Flex>
        </Flex>
    );
};

import { Box, Center } from "@chakra-ui/react";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import { IntroSection } from "../components/IntroSection";

export const HomePage = () => {
    return (
        <Box minHeight="100vh" h="auto" w="100%">
            <Navbar />
            <Center>
                <IntroSection />
            </Center>
            <Footer position="absolute" left="0" bottom="0" padding={3} />
        </Box>
    );
};
